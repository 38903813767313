import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import configureRoutes from './routes';
import SingleRoute from './single-route';
import { getCustomerCredit } from '../services/api/api.service';
import { useSelector } from 'react-redux';
import { Loader } from '../components';
import { LinkedInCallback } from "react-linkedin-login-oauth2";

const Routes = () => {
  const routes = configureRoutes();

  const { token, userDetail } = useSelector((store) => ({
    token: store.user.token,
    userDetail: store.user.userDetail,
  }));

  const customer_id = useSelector((e) => {
    console.log(e);
    if(e.user.userDetail!=null){
      return e.user.userDetail.userId;
    }
  }); 

  const [userAccess, setUserAcess] = useState(null);

  getCustomerCredit(customer_id)
    .then((e) => { 
      if (e.data.status == 200) {
        setUserAcess(true);
      } else {
        setUserAcess(false)
      }
    })
    .catch((e) => {
      setUserAcess(false)
    });

  if(userAccess === null) return <Loader visible={true} open={true} />

  return (
    <Switch>
      <Route path="/linkedin" component={LinkedInCallback} />
      {routes.map((route) => (
        <SingleRoute exact key={route.path} userAccess={userAccess} {...route} />
      ))}
      
    </Switch>
  );
};

export default Routes;
