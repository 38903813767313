import { fetch } from "../fetch.services";
import { API_PATH, GOOGLE_API_PATH } from "../../constants/path"
import { AUTH_TOKEN, GOOGLE_API_KEY, VENDOR_TOKEN } from '../../constants/token'

export const CategoriesService = () => {
  return fetch("get", API_PATH + `categories`, {}, {});
}

export const ProductService = () => {
  return fetch("get", API_PATH + `products`, {}, {});
}

export const FetchLinkedUserData = (api_key) => {
  return fetch("get", API_PATH + `linkedin_profile_fetch&authorization=${api_key}`, {}, {});
}

export const ProductServiceById = (id) => {
  return fetch("get", API_PATH + `products`, {}, {});
}

export const getPricingPlan = (id) => {
  return fetch("get", API_PATH + `RecruiterMembershipPlan`, {}, {});
}

export const getGeoLocation = (lat, long) => {
  return fetch("get", GOOGLE_API_PATH + `latlng=${lat},${long}&key=${GOOGLE_API_KEY}`, {}, {});
}

export const getCandidatedata = (industry = '') => {
  return fetch("get", API_PATH + `candidates&industry=${industry}`, {}, {});
}

export const validateEmailAddress = (email) => {
  return fetch("get", API_PATH + `validateEmailAddress&email=${email}`, {}, {});
}

export const getCandidateDetails = (id) => {
  return fetch("get", API_PATH + `candidatesById&candidate_id=${id}`, {}, {});
}
export const companyCandidateView = (company_id, customer_id) => {
  return fetch("get", API_PATH + `company_canididate_view&company_id=${company_id}&candidate_id=${customer_id}`, {}, {});
}
export const companyResumeViewed = (company_id) => {
  return fetch("get", API_PATH + `company_resume_view&company_id=${company_id}`, {}, {});
}

export const RecruiterMembershipPlanbyCustomerId = (company_id) => {
  return fetch("get", API_PATH + `RecruiterMembershipPlanbyCustomerId&customer_id=${company_id}`, {}, {});
}

export const getjobdata = (params) => {
  return fetch("get", API_PATH + `JobsByRecruiterId&recruiter_id=${params}`, {}, {});
}
export const Getjobs = () => {
  return fetch("get", API_PATH + `Jobs`, {}, {});
}
export const Addon = () => {
  return fetch("get", API_PATH + `RecruiterAddonPlan`, {}, {});
}

export const ManageApplications = (recruiter_id, job_id) => {
  return fetch("get", API_PATH + `candidatesbyrecruiterid&recruiter_id=${recruiter_id}&job_id=${job_id}`, {}, {});
}

export const ManageAllApplications = (recruiter_id) => {
  return fetch("get", API_PATH + `allcandidatesbyrecruiterid&recruiter_id=${recruiter_id}`, {}, {});
}

export const SaveRecruiter = (options) => {
  return fetch("post", API_PATH + 'saveRecruiter', JSON.stringify(options), {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  });
}

export const updateRecruiter = (options) => {
  return fetch("post", API_PATH + 'updateRecruiter', JSON.stringify(options), {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  });
}

export const changePasswordApi = (options) => {
  return fetch("post", API_PATH + 'changePassword', JSON.stringify(options), {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  });
}

export const SaveEnquiry = (options) => {
  return fetch("post", API_PATH + 'saveEnquiry', JSON.stringify(options), {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  });
}
export const SaveOrderID = (data,value) => {
  return fetch("get", API_PATH + `saveOrder&customer_id=${data}&package_id=${value}`, {}, {});
};
export const PaymentResponse = (data, value, payStatus) => {
  return fetch("get", API_PATH + `paymentResponse&customer_id=${data}&purchase_id=${value}&payment_status=${payStatus}`, {}, {});
};


export const getVendorData = (token) => {
  return fetch("post", API_PATH + `getVendor&sessToken=${token}`, JSON.stringify(), {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  });
};
export const getCountryData = () => {
  return fetch("get", API_PATH + 'country', {}, {});
};
export const getIndustries = () => {
  return fetch("get", API_PATH + 'industryListing', {}, {});
};
export const getCountryCodes = () => {
  return fetch("get", API_PATH + 'countryCodes', {}, {});
};
export const getCountry = () => {
  return fetch("get", API_PATH + `country`, {}, {});
};
export const savePost = (options) => {
  return fetch("post", API_PATH + 'savePostJobs', JSON.stringify(options), {});
};
export const updatePost = (options) => {
  return fetch("post", API_PATH + 'updatePostJobs', JSON.stringify(options), {});
};
export const saveReferal = (options) => {
  return fetch("post", API_PATH + 'saveReferal', JSON.stringify(options), {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  });
};
export const informationPage = () => {
  return fetch("get", API_PATH + 'InformationPages', {}, {});
};

export const specializationListing = () => {
  return fetch("get", API_PATH + 'specializationListing', {}, {});
};

export const informationDetails = (p_id) => {
  return fetch("get", API_PATH + `InformationDetails&page_id=${p_id}`, {}, {});
};

export const getJobsByID = (job_id) => {
  return fetch("get", API_PATH + `JobsById&job_id=${job_id}`, {}, {});
}


export const getcandidatesFeatured = (job_id) => {
  return fetch("get", API_PATH + `candidatesFeatured`, {}, {});
}

export const getCustomerCredit = (customer_id) => {
  return fetch("get", API_PATH + `getCustomerCredit&customer_id=${customer_id}`, {}, {});
}

export const getCountryByCountryId = (country_id) => {
  return fetch("get", API_PATH + `countryBycountry_id&country_id=${country_id}`, {}, {});
}

export const postFreeJobs = (options) => {
  return fetch("post", API_PATH + `postFreeJobs`, JSON.stringify(options), {});
}