import React from 'react';
import ReactDOM from 'react-dom';
import Application from './src';
import { unregister } from './service-worker';

const element = document.querySelector('#skulman');

ReactDOM.render(<Application />, element);
 
unregister();


