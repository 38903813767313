import { SAVE_SPECIALIZATION } from '../actions/user-action-types';

const initialState = []

export default function SpecializationReducer(state = initialState, {
    payload,
    type
}) {
    switch(type) {
        case SAVE_SPECIALIZATION:
            console.log('payload',payload)
            return [...state, payload]
        default:
            return state
    }
}