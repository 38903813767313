import { exact } from 'prop-types';
import React from 'react';
import { components } from 'react-select';
import {
  USER_SUPER_ADMIN,
  USER_SCHOOL_ADMIN,
  USER_PARENT,
  USER_STUDENT,
  USER_TEACHER
} from '../constants';



// Common
const NotFound = React.lazy(() => import('../pages/not-found'));
const ForbiddenAccess = React.lazy(() => import('../pages/forbidden-access'));
const Cart = React.lazy(() => import('../pages/cart'))

// User onboarding
const Login = React.lazy(() => import('../pages/user/login'));
const ForgotPassword = React.lazy(() => import('../pages/user/forgot-password'));
const ChangePassword = React.lazy(() => import('../pages/user/change-password'));
const MyProfile = React.lazy(() => import('../pages/user/my-profile'));
const EditProfile = React.lazy(() => import('../pages/user/edit-profile'));
const Dashboard = React.lazy(() => import('../pages/dashboard'));
const Registration = React.lazy(() => import('../pages/user/registration'));

// Home
const Home = React.lazy(() => import('../pages/homepage/index'));
const Recruiter = React.lazy(() => import('../pages/homepage/recruiterPage'));
const PricingPlans = React.lazy(() => import('../pages/homepage/pricingPlans'));
const About = React.lazy(() => import('../pages/informationPages/about'));
const Contact = React.lazy(() => import('../pages/informationPages/contact'));

const ViewCandidate = React.lazy(() => import('../pages/viewCandidates'))
const PostJobs = React.lazy(() => import('../pages/post'))
const EditJobs = React.lazy(() => import('../pages/post/editJobs'))
const Referal = React.lazy(() => import('../pages/referal'))
const Jobs = React.lazy(() => import('../pages/jobs'))
const ApplicationJobs = React.lazy(() => import('../pages/jobs/application'))
const Addons = React.lazy(() => import('../pages/addOns'))
const AboutUs = React.lazy(() => import('../pages/aboutUs/index'))

const PostFreeJobs = React.lazy(() => import('../pages/postFreeJobs/index'))

const configureRoutes = () => {
  const routes = [
    {
      component: Home,
      exact: true,
      path: '/',
      title: 'Sky Consultant Group',
      type: 'public',
      userValidate: false
    },
    {
      component: Recruiter,
      exact: true,
      path: '/recruiter',
      title: 'Recruiter',
      type: 'public',
      userValidate: false
    },
    {
      component: About,
      exact: true,
      path: '/about',
      title: 'About',
      type: 'public',
      userValidate: false
    },
    {
      component: Contact,
      exact: true,
      path: '/contact',
      title: 'Contact',
      type: 'public',
      userValidate: false
    },
    {
      component: Registration,
      exact: true,
      path: '/recruiter-registration',
      title: 'Recruiter Registration',
      type: 'public',
      userValidate: false
    },
    {
      component: PostJobs,
      exact: true,
      path: '/post-jobs',
      title: 'Job',
      type: 'private',
      userValidate: false
    },
 
    {
      component: EditJobs,
      exact: true,
      path: '/edit-job/:job_id',
      title: 'Job',
      type: 'private',
      userValidate: false
    },
    {
      component: Jobs,
      exact: true,
      path: '/manage-jobs',
      title: 'Manage Jobs',
      type: 'private',
      userValidate: false
    },
    {
      component: Cart,
      exact: true,
      path: '/cart/:pname/:pid',
      title: 'Cart Page',
      type: 'private',
      userValidate: false
    },
    {
      component: Addons,
      exact: true,
      path: '/addons',
      title: 'Addons',
      type: 'private',
      userValidate: false
    },
    {
      component: ApplicationJobs,
      exact: true,
      path: '/manage-application/:job_id',
      title: 'Manage Jobs Application',
      type: 'private',
      userValidate: false
    },
    {
      component: Referal,
      exact: true,
      path: '/referal',
      title: 'Referal',
      type: 'private',
      userValidate: false
    },
    {
      component: PostFreeJobs,
      exact: true,
      path: '/post-free-job',
      title: 'Post Job Requirement for Free - Hire Canididate',
      type: 'public',
      userValidate: false
    },
    {
      component: PricingPlans,
      exact: true,
      path: '/pricing-plans',
      title: 'Pricing Plans',
      type: 'public',
      userValidate: false
    },
    {
      component: Login,
      exact: true,
      path: '/login',
      title: 'Login',
      type: 'public',
      userValidate: false
    },
 
    {
      component: ViewCandidate,
      exact: true,
      path: '/view-candidate',
      title: 'ViewCandidate',
      type: 'private',
      userValidate: false
    },
    {
      component: AboutUs,
      exact: true,
      path: '/detailPage/:pname/:pid',
      title: 'AboutUS',
      type: 'public',
      userValidate: false
    },
    {
      component: ForgotPassword,
      exact: true,
      path: '/forgot-password',
      title: 'Forgot Password',
      type: 'public',
      userValidate: false
    },
    {
      accessible: [],
      component: Dashboard,
      exact: true,
      path: '/dashboard',
      title: 'Dashboard',
      type: 'private',
      userValidate: false
    },
    {
      accessible: [USER_SUPER_ADMIN, USER_SCHOOL_ADMIN, USER_STUDENT, USER_TEACHER, USER_PARENT],
      component: ChangePassword,
      exact: true,
      path: '/change-password',
      title: 'Change Password',
      type: 'private',
      userValidate: false
    },
    {
      accessible: [USER_SUPER_ADMIN, USER_SCHOOL_ADMIN, USER_STUDENT, USER_TEACHER, USER_PARENT],
      component: MyProfile,
      exact: true,
      path: '/my-profile',
      title: 'My Profile',
      type: 'private',
      userValidate: false
    },
    {
      accessible: [USER_SUPER_ADMIN, USER_SCHOOL_ADMIN, USER_STUDENT, USER_TEACHER, USER_PARENT],
      component: EditProfile,
      exact: true,
      path: '/edit-profile',
      title: 'Edit Profile',
      type: 'private',
      userValidate: false
    },





    // General routes
    {
      component: ForbiddenAccess,
      exact: true,
      path: '/forbidden-access',
      title: '403 Forbidden Access',
      type: 'error',
      userValidate: false
    },
    {
      component: NotFound,
      exact: true,
      path: '*',
      title: '404 Not Found',
      type: 'error',
      userValidate: false
    },
  ];

  return routes;
};

export default configureRoutes;
