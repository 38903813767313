import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getVendorData } from "../services/api/api.service";

const AuthenticationHOC = (Childcomponent) => {
    return (props) => {
        const token = useSelector((e)=>e.user.token) 
        const [isAuthenticate, setIsAuthenticate] = useState(null)
        const [data, setData] = useState({

        })
        useEffect(()=>{
            getVendorData(token)
            .then((e)=>{
                if(e.data.status == 200) {
                    setIsAuthenticate(true)
                    setData(e.data.data[0])
                } else {
                    setIsAuthenticate(false)
                }
            })
        },[token])
        return <Childcomponent isAuthenticate={isAuthenticate} user_data={data} {...props} />
    }
}

export default AuthenticationHOC
