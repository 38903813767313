import React, { Component } from "react";
import { getGeoLocation, CategoriesService } from '../../services/api/api.service'
import { Redirect } from 'react-router'
import { Link } from "react-router-dom";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { logoutSuccess } from '../../actions/user-action-types';
import { useDispatch } from 'react-redux';
import { useState } from "react";

const Header = ({
    isDrawerOpen,
    user
}) => {
    const dispatch = useDispatch();
    const [dropdown, setDropdown] = useState(false)
    const openDropdown = () => {
        setDropdown(true)
    }
    const closeDropdown = () => {
        setDropdown(false)
    }
    const onLogout = () => {
        dispatch(logoutSuccess());
        dispatch(push('/'));
    };
    console.log(user)

    return (
        <>
            <div class="container_fluid header_sky">

                <div class="container">
                    <div class="sky_logo">
                        <Link to={"/"}><img src="../../public/images/img1.PNG" alt="" /></Link>
                    </div>
                    {!(Object.keys(user).length) == 0 ? <div class="nav_menus">
                        <ul>
                            <li><a onClick={onLogout}>Logout</a></li>
                            <li class="hide_mobile"><Link class="register_btn" to={"/dashboard"}>Dashboard</Link></li>
                            <li className="drawer_dropdown">
                                {dropdown ? <IconButton aria-label="delete" size="large" onClick={() => closeDropdown()}>
                                    <CloseIcon fontSize="inherit" />
                                </IconButton> : <IconButton aria-label="delete" size="large" onClick={() => openDropdown()}>
                                    <MenuIcon fontSize="inherit" />
                                </IconButton>}
                                {dropdown ? <ul>
                                    <li><Link to={'/view-candidate'}>Candidate Search</Link></li>
                                    <li><Link to={'/pricing-plans'}>Pricing Plans</Link></li>
                                    <li><Link to={'/post-jobs'}>Post Job</Link></li>
                                    <li><Link to={'/manage-jobs'}>Manage Jobs</Link></li> 
                                    <li><Link to={'/referal'}>Referal</Link></li>
                                    <li><Link to={'/change-password'}>Change Password</Link></li>
                                    <li><Link to={'/addons'}>Addons</Link></li>
                                    <li><Link to={'/edit-profile'}>Edit Profile</Link></li>
                                </ul> : null}
                            </li>

                            <li class="hide_mobile"><Link to={"/"}>Home</Link></li>
                        </ul>
                    </div> : <div class="nav_menus">
                        <ul>
                        <li className="drawer_dropdown hide_on_dexktop">
                                {dropdown ? <IconButton aria-label="delete" size="large" onClick={() => closeDropdown()}>
                                    <CloseIcon fontSize="inherit" />
                                </IconButton> : <IconButton aria-label="delete" size="large" onClick={() => openDropdown()}>
                                    <MenuIcon fontSize="inherit" />
                                </IconButton>}
                                {dropdown ? <ul>
                                    <li><Link to={'/post-free-job'}>Post Free Job ( New User )</Link></li>
                                    <li><Link to={'/login'}>Login ( Old User )</Link></li>
                                </ul> : null}
                            </li>
                            <li className="dropdown hide_mobile"><Link to={'#'}>Hire Now <i class="fa fa-angle-down" aria-hidden="true"></i></Link>
                                <ul>
                                    <li><Link to={'/post-free-job'}>Post Free Job ( New User )</Link></li>
                                    <li><Link to={'/login'}>Login ( Old User )</Link></li>
                                </ul>
                            </li>
                                    
                            <li class="hide_mobile"><Link to={"/contact"}>Contact Us</Link></li>
                            {/* <li><Link to={"/"}>Blogs</Link></li> */}
                             <li><Link to={'/pricing-plans'}>Pricing Plans</Link></li>  
                            <li class="hide_mobile"><Link to={"/about"}>About Us</Link></li>
                            <li class="hide_mobile"><Link to={"/"}>Home</Link></li>
                        </ul>
                    </div>}
                </div>
            </div>
        </>
    );
}
export default Header;