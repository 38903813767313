import React, { useEffect, useState } from "react";
import { informationPage } from "../../services/api/api.service";
import { NavLink as Link } from 'react-router-dom';

const Footer = () => {
    const [data, getdata] = useState([])

    useEffect(() => {
        informationPage()
            .then((res) => {
                if (res.data.status == 200) {
                    getdata(res.data.data)
                } else {
                    getdata([])
                }
            })
    }, [])
    return (
        <div class="container_fluid footer">
            <div class="container">
                <div className="botom_links">
                    <h5>Other Pages</h5>
                    <ul>
                        <li><Link to={"/"}>Home</Link></li>
                        <li><Link to={"/about"}>About Us</Link></li>
                        <li><Link class="log_in_btn" to={"/login"}>Login</Link></li>
                        <li><Link class="register_btn" to={"/recruiter-registration"}>Register</Link></li>
                        <li><Link to={"/contact"}>Contact Us</Link></li>
                        <li><Link to={'/pricing-plans'}>Pricing Plans</Link></li>



                    </ul>
                </div>
                <div className="botom_links">
                    <h5>Information Pages</h5>
                    <ul>
                        {data.map((e) => (<li><Link to={`/detailPage/${e.pname.replace(' ', '-')}/${e.id}`}>{e.pname}</Link></li>))}
                    </ul>
                </div>
                <div class="footer_below">
                    <div class="social_media_icons">
                        <ul>

                            <li><a target={'_blank'} href="https://www.facebook.com/people/Sky-Consultant-Group/100063911748788/"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>

                        </ul>

                    </div>
                    <p>&copy; 2021 Copyright Sky Consultant Group. All rights reserved.</p>

                </div>
            </div>
            <div className="whatsapp"><a href="https://api.whatsapp.com/send?phone=+35679083646&text=Hello Sky Consultant Group :- "><i class="fa fa-whatsapp" aria-hidden="true"></i></a></div>
        </div>

    );

}
export default Footer;