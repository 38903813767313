import React from 'react';

const DocumentSVGIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 219.376 219.376"
    style={{ enableBackground: 'new 0 0 219.376 219.376' }}
    xmlSpace="preserve"
    fill="#3ac47d"
    height={20}
  >
    <path d="M127.518,0H40.63c-6.617,0-12,5.383-12,12v195.376c0,6.617,5.383,12,12,12h138.117c6.617,0,12-5.383,12-12V59.227c0-3.204-1.248-6.217-3.513-8.484L135.869,3.382C133.618,1.199,130.66,0,127.518,0zM175.747,204.376H43.63V15h71.767v40.236c0,8.885,7.225,16.114,16.106,16.114h44.244V204.376zM131.503,56.35c-0.609,0-1.105-0.5-1.105-1.114v-31.58l34.968,32.693H131.503zM69.499,88.627h81.5v15h-81.5V88.627z M69.499,113.627h81.5v15h-81.5V113.627z M69.499,138.626h81.5v15h-81.5V138.626zM69.499,163.626h46.5v15h-46.5V163.626z" />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

export default DocumentSVGIcon;
